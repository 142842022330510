@media (max-width: 499px) {
  /*-----------------------------------------------------------------------------------*/
  /*  One Page portfolio
  /*-----------------------------------------------------------------------------------*/
  //Features
  .standout {
    text-align: center;
    h4 {
      margin-bottom: 10px;
    }
    .theme-btn {
      margin-top: 0;
    }
  }

  // services list
  .our-services-list .container {
    padding-top: 30px;
  }

  #comments {
    .depth-2 article,
    .depth-3 article,
    .depth-4 article,
    .depth-5 article {
      margin-left: 0;
    }
  }

  #contact-form .container-left,
  #contact-form .container-right {
    float: none;
    width: auto;
  }

  .contacts-list li {
    display: block;
    width: 240px;
    margin: 0 auto 50px;
  }

  .related-projects {
    display: none;
  }

  /*-----------------------------------------------------------------------------------*/
  /* Team var-4
  /*-----------------------------------------------------------------------------------*/
  .team-box {
    padding: 0 20px;
  }

  .variant-three {
    .contact-card .social_networks li a {
      width: 38px !important;
    }
    .contact-card .social_networks li:first-child a {
      border-left: 1px solid #65bbd9;
    }
  }

  /*-----------------------------------------------------------------------------------*/
  /* follow us slider home 4
  /*-----------------------------------------------------------------------------------*/
  .follow-us.variant-four {
    .flexslider {
      .flex-viewport {
        ul {
          li {
            .custom-container-follow {
              max-width: 235px;

            }
          }
        }
      }
    }

  }

}
