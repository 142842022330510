/*-----------------------------------------------------------------------------------*/
/*  Contact us Section
/*-----------------------------------------------------------------------------------*/
.contact-us {
  background-color: @contact_us_background_color;

  .container {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .section-header {
    margin-bottom: 70px;
    .title {
      color: @white;
    }
    p {
      color: @contact_us_subtitle_text_color;
      font-size: 16px;
    }
  }

  .separator .line {
    background-color: @contact_us_title_line_color;
  }

  .contact-form {
    margin: auto 200px;

    ::-webkit-input-placeholder {
      color: @contact_us_imput_text_color;
    }
    ::-moz-placeholder {
      color: @contact_us_imput_text_color;
    }
    /* firefox 19+ */
    :-ms-input-placeholder {
      color: @contact_us_imput_text_color;
    }
    /* ie */
    input:-moz-placeholder {
      color: @contact_us_imput_text_color;
    }
    input {
      border: 1px solid transparent;
      transition: .2s all ease-in-out;
      &:focus {
        color: @contact_us_imput_text_color;
        border-color: #ffffff;
      }

    }

    input,
    textarea {
      color: @contact_us_imput_text_color;
      background-color: @contact_us_input_background_color;
      height: 43px;
      padding-left: 16px;
      padding-right: 16px;
      width: 100% !important;
      margin-top: 30px !important;
    }
    textarea {
      padding-top: 10px;
      padding-bottom: 10px;
      resize: none;
      width: 100%;
      height: 151px;
      border: 1px solid transparent;
      transition: .2s all ease-in-out;
      &:focus {
        color: @contact_us_imput_text_color;
        border-color: #ffffff;
      }
    }
    input[type="submit"]{
      width: inherit!important;
      margin: auto;
      display: block;
      background-color: #253c82;
      color: #FFF;
      border-color: #FFF;
      font-size: 14px;
      font-weight: 700;
      font-family: Raleway;

    }
    p {
      overflow: hidden;
      margin-bottom: 28px;
    }
    #email,
    .wpcf7-text,
    #name {
      width: 48%;
    }
    .wpcf7-text,
    #name {
      float: left;
    }
    .wpcf7-email,
    #email {
      float: left;
    }
    #subject {
      float: right;
    }
    #tel{
      float: right;
    }
    #submit {
      font-size: 14px;
      color: @contact_us_submit_text_color;
      background: none;
      border: 2px solid @contact_us_submit_border_color;
      padding: 3px 33px 1px;
      float: right;
      &:hover {
        color: @contact_us_submit_text_color_hover;
        background-color: @contact_us_submit_bg_color_hover;
        border-color: @contact_us_submit_border_color_hover;
      }
    }

    #contact-loader {
      display: none;
      margin: 9px 10px 0 0;
    }

    input.error,
    textarea.error {
      border: 1px solid @white;
    }
  }

  .status-wrapper {
    float: left;
  }

  .submit-and-loader {
    float: right;
  }

  .error-container {
    display: none;
    label.error {
      font-weight: 500;
      color: @white;
      display: block;
    }
  }
  .wpcf7-not-valid {
  border: 2px solid #f00 !important;
  }
  .wpcf7-not-valid-tip {
  display: none;
  }
  #message-sent {
    display: none;
  }
  .square{
    background-color: #FFF;
  }

}

.contact-us.variant-three {
  background-color: #674296;

  .contact-form #contact-loader {
    margin: 15px 10px 0 0;
  }

  ::-webkit-input-placeholder {
    color: #afa0c3;
  }
  ::-moz-placeholder {
    color: #afa0c3;
  }
  /* firefox 19+ */
  :-ms-input-placeholder {
    color: #afa0c3;
  }
  /* ie */
  input:-moz-placeholder {
    color: #afa0c3;
  }

  .section-header {
    h2.title {
      font-size: 48px;
    }
    p {
      color: #b297d4;
    }
  }

  span.line {
    background-color: #744da7;
  }

  input,
  textarea {
    color: #afa0c3;
    background-color: @white;
    &:focus {
      color: #373c40;
    }
  }
  textarea {
    display: block;
  }

  #submit {
    background: #e76c99;
    border-color: #e76c99;
    &:hover {
      background-color: #532a87;
      border-color: #532a87;
    }
  }

  input.error,
  textarea.error {
    border-color: #e76c99;
  }

}

.contact-us.variant-four {
  .contact-form #contact-loader {
    margin: 15px 10px 0 0;
  }

  .section-header {
    h2.title {
      margin-bottom: 15px;
    }
  }

  ::-webkit-input-placeholder {
    color: #a0a0a0;
  }
  ::-moz-placeholder {
    color: #a0a0a0;
  }
  /* firefox 19+ */
  :-ms-input-placeholder {
    color: #a0a0a0;
  }
  /* ie */
  input:-moz-placeholder {
    color: #a0a0a0;
  }

  input,
  textarea {
    color: #a0a0a0;
    background-color: @white;
    width: 100% !important;
    margin-top: 30px !important;
    &:focus {
      color: #080808;
    }
  }

  input[type="submit"]{
    width: inherit!important;
    margin: auto;
    display: block;
  }

  input.error,
  textarea.error {
    border-color: #ff0000;
    box-shadow: inset 0 0 1px 1px #ff0000;
  }

}
div.wpcf7-mail-sent-ok{
  border-color: #FFF;
  color: white;
}

div.wpcf7-validation-errors {
  border: 2px solid #fff;
  color: #FFF;
}

div.wpcf7 .ajax-loader{
  display: block;
  margin: auto;
  margin-top: 20px;
}
