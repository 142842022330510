@media (max-width: 479px) {
  /*-----------------------------------------------------------------------------------*/
  /*  Header
  /*-----------------------------------------------------------------------------------*/
  .contact-card {
    margin: 0 -15px;
    float: none;
    text-align: center;
    .social_networks {
      float: none;
      li:first-child a {
        border-left: none;
      }
    }
  }

  .header-wrapper.variant-three .contact-card {
    border-bottom: 1px solid #d3dae0;
  }

  .wrapper-contact-options {
    margin: 0 45px;
  }

  .logo-wrapper > #logo {
    display: block;
    height: auto;
  }

  &.variant-one {
    #logo {
      height: 60px;
    }
  }

  .header-wrapper {
    &.variant-one {
      .mean-container a.meanmenu-reveal {
      }
    }

    &.variant-two {
      #logo {
        padding: 0;
      }
      .mean-container a.meanmenu-reveal {
        padding: 20px 14px;
      }
    }

    &.variant-three {
      .contact-card {
        margin: 0;
        border: none;
      }
      .contact-card .social_networks {
      }
    }
  }

  .our-work,
  .testimonial,
  .flexslider {
    display: block;
  }

  .flexslider {
    .flex-direction-nav {
      a {
        background: none;
        border: none;
        &:hover {
          background: none;
        }
      }
    }
  }

  /*-----------------------------------------------------------------------------------*/
  /*  One Page portfolio
  /*-----------------------------------------------------------------------------------*/
  .section .section-header h2.title {
    font-size: 32px;
    line-height: 48px;
  }

  .section-header .separator .line {
    width: 100px;
  }

  .features,
  .about-us,
  .our-team,
  .our-blog,
  .our-services-list,
  .our-services,
  .contact-us {
    .container {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  // Our Team
  .post-grid-3-col,
  .our-team {
    .col-xs-6 {
    }
  }

  // Our Services
  .our-services.variant-two .list-large-arrow-bullet {
    ul.pull-left,
    ul.pull-right {
      width: 100%;
    }
  }

  // Features
  .features.variant-two .standout {
    text-align: center;
  }

  // Contact Detail
  .contact-details-list {
    border: none;
    li {
      float: none;
      border: none;
      padding: 5px 0 15px;
      width: 200px;
      i {
        height: 26px;
        margin-bottom: 0;
      }
      .icon-telephone,
      .icon-fax,
      .icon-mail {
        background-size: 24px;
      }
    }
  }

  .contact-details {
    .section-header {
      margin-bottom: 83px;
    }
  }

  // Contact
  .contact-us {
    .status-wrapper,
    .submit-and-loader {
      float: none;
      text-align: center;
    }
    .contact-form {
      #name {
        margin-bottom: 28px;
      }
      #email,
      #name {
        width: 100%;
      }

      #submit {
        float: none;
        margin-top: 20px;
      }
      #contact-loader {
        margin: 20px auto;
      }
    }
  }

  .tabs-nav li {
    float: none;
    margin-bottom: 2px;
  }

  .author-bio {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    .gravatar {
      float: none;
      padding: 0;
      display: inline-block;
      .avatar {
        margin-bottom: 15px;
      }
    }
  }

  #comments {
    .avatar {
      width: 60px;
      height: 60px;
    }
    .author-photo {
      padding: 0;
      width: auto;
    }
    .comment {
      padding: 15px 0;
    }
    .comment-wrapper {
      padding: 0 10px;
    }
  }

  #contact-form #message-sent,
  #contact-form .error-container {
    text-align: center;
    position: static;
  }

  #contact-form #message-sent label,
  #contact-form .error-container label,
  #contact-form p.text-right {
    text-align: center;
  }

  .service-icon {
    margin: 0 0 30px;
  }

  .services-items-wrapper article .content-wrapper {
    padding: 20px;
  }

  .service-icon,
  .services-items-wrapper article .content-wrapper,
  .services-items-wrapper .icon-container {
    float: none;
    width: auto;
  }

  .isotope-item.col-xs-6 {
  }

}



