/*-----------------------------------------------------------------------------------*/
/*  Testimonial Section
/*-----------------------------------------------------------------------------------*/
.testimonial {
  > div{
    padding-top: 77px;
  }
  &:before{
    content: url("../images/down-triangle.png");
    position: relative;
    top: -1px;
  }
  text-align: center;
  padding-bottom: 70px;
  background-color: @testimonials_bg_color;
  background-attachment: fixed;
  background-size: 100%;
  .flexslider .flex-direction-nav {
    display: none !important;
  }
}

.quote {
  .icon-quote-start {
    background: url('../images/icon-quote-start-4.png') no-repeat 0 0;
  }

  .icon-quote-end {
    background: url('../images/icon-quote-end-4.png') no-repeat 0 0;
  }

  blockquote {
    border: none;
    margin: 0;
    padding: 0;
    i {
      display: inline-block;
      width: 120px;
      height: 54px;
    }
    p {
      color: @testimonials_color;
      font-size: 30px;
      line-height: 42px;
      padding: 9px 0;
    }
    .author {
      font-size: 21px;
      line-height: 42px;
      color: @testimonials_author_color;
    }
    .separator {
      margin-bottom: 2px;
      .line {
        background-color: @testimonials_line_color;
        width: 30%;
      }
      .square {
        background-color: @white;
      }
    }
    .row-testimonial{
      display: flex;
    }
  }
}

.testimonial.variant-two {
  background: #edf1f4;
  padding-top: 66px;
  padding-bottom: 68px;

  i {
    display: block;
    width: 9.11111111111111%;
    height: 54px;
    &.icon-quote-start {
      float: left;
      background: url('../images/icon-quote-start-2.png') no-repeat left 0;
    }

    &.icon-quote-end {
      float: right;
      background: url('../images/icon-quote-end-2.png') no-repeat right 0;
    }
  }

  blockquote {
    width: 79.77777777777778%;
    float: left;
    border: none;
    margin: 0;
    padding: 0;
    p {
      color: #55595c;
      font-size: 24px;
      line-height: 36px;
      padding: 0;
      margin-bottom: 6px;
    }
    .author {
      font-size: 18px;
      color: #55595c;
      margin-top: 0;
    }
  }
}

.testimonial.variant-three {
  background: #6ecaea;

  .quote {

    p {
      color: #3e4a4e;
    }

    .line {
      background-color: #61b3cf;
    }

    .square {
      background-color: #3c6e80;
    }
  }

  .icon-quote-start {

    background-image: url('../images/icon-quote-start-3.png');
  }

  .icon-quote-end {

    background-image: url('../images/icon-quote-end-3.png');
  }

}

.testimonial.variant-four {
  padding-top: 120px;
  padding-bottom: 110px;

  background: url("../images/temp/testimonial-section-bg-image-2.jpg");

  .quote a, p strong {
    color: #f16543;
  }

  .quote .line {
    background-color: #585a5d;
    width: 160px;
  }

  .quote .square {
    background-color: @white;
  }

  .separator {
    margin-bottom: 0px;
  }

  .quote .author {
    color: @white;
    margin-top: 23px;
  }

  .icon-quote-start {

    background-image: url('../images/icon-quote-start-4.png');
  }

  .icon-quote-end {

    background-image: url('../images/icon-quote-end-4.png');
  }

}

@media (max-width: 768px) {
  .quote blockquote .row-testimonial{
    display: block;
  }
}
