/*-----------------------------------------------------------------------------------*/
/*  Contact Page
/*-----------------------------------------------------------------------------------*/
#map_canvas {
  height: 670px;
}

#contact-form {
  position: relative;

  .form-heading {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
  }

  .contact-form {
    padding-top: 22px;
    max-width: 100%;
    margin-bottom: 0;
  }

  .container-left,
  .container-right {
    width: 49%;
  }

  .container-left {
    float: left;
  }

  .container-right {
    float: right;
  }

  label {
    color: #70777e;
    font-size: 14px;
    font-weight: normal;
    display: block;
    margin-bottom: 8px;
    text-align: left;
    span {
      color: #ef1212;
    }
  }

  p {
    margin-bottom: 22px;
  }

  input[type="text"],
  textarea {
    color: @heading_color;
    background: none;
    border: 1px solid #d0d6d9;
    &:focus {
      outline: none;
    }
  }

  input[type="text"] {
    height: 50px;
    width: 100%;
    padding: 0 10px;
  }

  textarea {
    height: 257px;
    width: 100%;
    margin-bottom: 14px;
    padding: 10px;
    resize: none;
  }

  input.error,
  textarea.error {
    border: 1px solid #f16543;
  }

  label.error {
    display: block;
    color: #f16543;
  }

  #message-sent,
  .error-container {
    position: absolute;
    bottom: -20px;
    left: 0;
  }
}

.error-container,
#message-sent,
#contact-loader {
  display: none;
}

#contact-form,
.wpcf7,
.wpcf7-form-control-wrap {
  #submit {
    padding: 0 26px;
  }
}

.wpcf7,
.wpcf7-form-control-wrap {

  p {
    color: #70777e;
    font-size: 14px;
    margin-bottom: 22px;
  }

  input[type="email"],
  input[type="text"],
  textarea {
    color: @heading_color;
    background: none;
    border: 1px solid #d0d6d9;
    &:focus {
      outline: none;
    }
  }

  input[type="email"],
  input[type="text"] {
    height: 50px;
    width: 40%;
    padding: 0 10px;
  }

  textarea {
    height: 257px;
    width: 60%;
    margin-bottom: 16px;
    padding: 10px;
    resize: none;
  }
}

.contacts-list {
  list-style: none outside none;
  padding: 70px 0 0;
  text-align: center;

  li {

    .inner-contact {
      display: inline-block;
      width: 220px;
      transition: .2s all ease-in-out;
    }
    h4 {
      text-transform: uppercase;
      margin-bottom: 5px;
      transition: .2s all ease-in-out;
      + .separator {

        margin: 0;
      }
    }
    .line {
      width: 85px;
    }
    p {
      padding-top: 22px;
    }
    i {
      transition: .2s all ease-in-out;
      float: left;
      margin: 1px 10px 15px;
      font-size: 46px;
      color: #80878d;
    }
    a {
      color: #80878d;
      &:hover {
        color: @anchor_color;
      }
    }
    &:hover {
      h4,
      i {
        color: @anchor_color;
      }
      .square {
        transition: .2s all ease-in-out;
        background-color: @anchor_color;
      }

    }
  }
}
