/*-----------------------------------------------------------------------------------*/
/*  Portfolio Page
/*-----------------------------------------------------------------------------------*/
.portfolio-item-filter {
  text-align: center;
  padding: 15px 0;
  margin: 60px auto;
  a {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    color: #9da1a3;
    padding: 2px;
    margin: 2px 15px;
    text-decoration: none;
    &:hover,
    &.active {
      color: @anchor_color;
    }
  }
}

.portfolio-item,
.gallery-item {
  margin: 0 0 35px;
  overflow: hidden;
}

.portfolio-item {
  .item-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 9px;
    color: #4f5458;
  }
  time {
    color: #687075;
  }
  span {
    color: @anchor_color;
  }
  .square {
    background-color: #bfc4c8;
  }
}

.related-projects .item,
.portfolio-item {
  .separator {
    margin: 30px 0 15px;
  }

  figure {
    margin-bottom: 25px;
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(241, 101, 67);
    a {
      .opacity(0.8);
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      margin-top: -20px;
      margin-left: -20px;
      background: url("../images/icon-lense.png") no-repeat center center;
      &:hover {
        .opacity(1);
      }
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/* Single Portfolio Page
/*-----------------------------------------------------------------------------------*/
.single-project {
  padding-top: 50px;

  .sp-inner-wrapper {
    position: relative;
  }
  .sp-control-nav {
    position: absolute;
    top: -70px;
    right: 0;
    text-align: center;
  }
  .flex-direction-nav {
    display: block;
    li {
      float: left;
      margin-left: 5px;
      a {
        color: @white;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: #65696c;
        &:hover {
          background-color: #f16543;
        }
        i {
          line-height: 0;
        }
      }
    }
  }

  article {
    padding: 41px 98px 0;
    .title {
      margin-bottom: 10px;
      font-weight: 600;
    }
    .title + p {
      font-size: 13px;
    }
    p {
      font-size: 14px;
      line-height: 26px;
    }
    .row {
      padding-top: 25px;
      h4 {
        text-transform: uppercase;
      }
    }
  }
}

.related-projects {
  padding: 74px 0 0;
  overflow: hidden;

  .section-title {
    text-transform: uppercase;
    padding: 0 7px;
    margin-bottom: 0;
  }

  .related-projects-wrapper {
    margin-right: -30px;
    position: relative;
    padding: 36px 0 0;
  }

  .rp-control-nav {
    position: absolute;
    top: -34px;
    right: 30px;
    text-align: center;
    a {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      color: @white;
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background-color: #65696c;
      &.prev {
        margin-right: 3px;
      }
      &:hover {
        background-color: #f16543;
      }
      i {
        line-height: 0;
      }
    }
  }

  .item {
    padding-right: 30px;
  }

  .item-title {
    margin-bottom: 10px;
  }

  .square {
    background-color: #bfc4c8;
  }

}

