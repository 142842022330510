/*-----------------------------------------------------------------------------------*/
/* Follow us Section
/*-----------------------------------------------------------------------------------*/
@section_background_color : #5f6366;
@section_content_text_color: #ced3d8;
@section_link_color: #f16543;
.follow-us {
  text-align: center;
  background: url('../images/temp/follow-us-section-bg.jpg');
  background-attachment: fixed;
  background-size: cover;

  .flexslider {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .icon-twitter {
    display: block;
    width: 100%;
    height: 30px;
    background: url("../images/icon-twitter.png") no-repeat center 0;
    margin-bottom: 15px;
  }

  .section-header {
    h2.title {
      color: @white;
      font-size: 36px;
      line-height: 41px;
    }
  }

  .separator .line {
    background-color: #6b7074;
  }

  .widget_displaytweetswidget {
    margin: 0;
    li {
      p {
        font-size: 27px;
        line-height: 42px;
        color: @section_content_text_color;
        border: none;
      }
      a {
        color: @section_link_color;
      }
      small {
        font-size: 18px;
        color: #919ba1;
      }
    }
  }

}

.follow-us.variant-three {
  background: #e76c99;

  .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .icon-twitter {
    background-image: url("../images/icon-twitter-2.png");
  }

  .section-header {
    margin-bottom: 24px;
  }

  h2.title {
    color: #7c3751;
  }

  span.line {
    background-color: #ca668b;
  }

  .widget_displaytweetswidget {
    width: auto;
    margin-bottom: 0;
  }
  .widget_displaytweetswidget a,
  .widget_displaytweetswidget p {
    color: @white;
  }
  .widget_displaytweetswidget small {
    color: #9e4767;
    font-style: normal;
  }
  .slider-follow-var3 {
    padding-top: 0;
    padding-bottom: 0;
  }

}

.follow-us.variant-four {

  .flexslider {
    padding-top: 160px;
    padding-bottom: 150px;
    .flex-viewport {
      ul {
        li {
          .custom-container-follow {
            width: 1025px;
          }
        }
      }
    }
  }

  .section-header {
    margin-bottom: 17px;
  }

  i.icon-twitter {
    float: left;
    width: 147px;
    height: 147px;
    margin: 0 54px 0 0;
    background-color: #f16543;
    background-position: center center;
    .border-radius(100%);
  }

  .icon-twitter {
    background-image: url("../images/icon-twitter-3.png");
  }

  .widget_displaytweetswidget {
    width: auto;
    a {
      color: #f16543;
    }
    p {
      color: @white;
      text-align: left;
      padding-top: 30px;
      display: table;
    }
    small {
      color: #f16543;
      font-style: normal;
      text-align: center;
      display: block;
      margin-top: 20px;
    }
  }

}


