/*-----------------------------------------------------------------------------------*/
/*  Shop Section
/*-----------------------------------------------------------------------------------*/
.product-categories {
  padding: 20px 0;

  figure {
    position: relative;
    margin: 20px auto;
  }
  img {
    margin: 0;
  }
  figcaption {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #373c40;
    color: @white;
    font-size: 16px;
    line-height: 23px;
    font-weight: 700;
    padding: 10px 23px;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}

.featured-products {
  display: none;

  padding-top: 30px;
  padding-bottom: 30px;

  .section-header {
    margin-bottom: 50px;
  }

  .inner-contents {
    border-bottom: 1px solid #ced7de;
    margin-bottom: 30px;
  }
}

.products {
  display: none;
  background-color: #f7f9fa;
  padding-top: 30px;
  padding-bottom: 30px;
}