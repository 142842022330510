//
// Utility classes
// --------------------------------------------------

// Floats
// -------------------------

.clearfix {
  .clearfix();
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

// Toggling content
// -------------------------

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  .hide-text();
}

// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}
