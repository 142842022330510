/*-----------------------------------------------------------------------------------*/
/* Buy Now Section
/*-----------------------------------------------------------------------------------*/
@page-header-bg-color: #e9eef1;
.purchase-now {
  background-color: @page-header-bg-color;
  padding: 53px 0 40px;
  .container-box-sizing;
  p {
    font-size: 20px;
    line-height: 30px;
    a {
      transition: .2s all ease-in-out;
      color: @anchor_color;
      &:hover {
        color: @text-color;
      }
    }
  }

  .buy-now-btn {
    display: inline-block;
    background-color: @anchor_color;
    font-size: 20px;
    color: @white;
    line-height: 30px;
    padding: 10px 58px;
    margin-top: 10px;
    .theme-transition;
    &:hover {
      background-color: @heading_color;
    }
  }
}