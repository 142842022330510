/*-----------------------------------------------------------------------------------*/
/*  About Section
/*-----------------------------------------------------------------------------------*/
.about-us {
  background-color: #fcfcfc;
  .container {
    padding-top: 100px;
    padding-bottom: 30px;
  }

  .section-header {
    margin-bottom: 50px;
    h2.title {
      color: @heading_color;
    }
  }

}

.innards {
  text-align: center;
  margin-bottom: 32px;
  h3 {
    margin-bottom: 25px;
    margin-top: 44px;
    font-weight: 600;
  }
  .icon-container {
    position: relative;
    width: 130px;
    min-height: 130px;
    height: auto !important;
    height: 130px;
    text-align: center;
    color: @white;
    background: @arrow_box_color;
    margin: 0 auto 42px;
    i {
      display: block;
      line-height: 130px;
    }
    &:after {
      position: absolute;
      top: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      pointer-events: none;
      border-top-color: @arrow_box_color;
      border-width: 9px;
      left: 50%;
      margin-left: -9px;
    }
  }
}

.about-us.variant-two {
  background-color: #fcfcfc;

  .container {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .section-header {
    h2.title {
      color: @heading_color;
      font-size: 42px;
    }
  }

  figure {
    margin: 20px 0 30px;
    min-height: 100px;
    height: auto !important;
    height: 100px;
  }
}

.about-us.variant-three {
  background-color: #486bb1;

  .container {
    padding-bottom: 0;
  }
  .section-header {
    margin-bottom: 65px;

    h2.title,
    h3 {
      font-size: 36px;
      line-height: 42px;
    }

    h2.title {
      color: @white;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    h3 {
      font-weight: 300;
      color: #9fb8eb;
    }

  }

  .innards {
    h3 {
      color: #b6ceff;
      font-size: 21px;
      line-height: 21px;
      margin-bottom: 28px;
    }

    p {
      color: #86a2dc;
    }
  }

}

.about-us.variant-four {
  background-color: #fbf8f7;

  .container {
    padding-bottom: 0;
  }

  .section-header {
    h2.title {
      font-size: 48px;
      color: #534c4a;
      line-height: 58px;
      margin-bottom: 30px;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 30px;
      color: #524b49;
    }
  }

  .icon-box,
  figure {
    height: auto !important;
    margin: 35px 0;
    min-height: 80px;
  }

  .innards {
    color: #9d9897;
    border-top: 1px solid #eee6e4;
    h3 {
      font-weight: normal;
      color: #393432;
      margin-bottom: 20px;
    }
    a {
      color: #9d9897;
      transition: .2s all;
      &:hover {
        color: @anchor_color;
      }
    }
    a,
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }
    p {
      margin-bottom: 28px;
    }
  }
}

span.line-vertical {
  background: #eee6e4;
  display: block;
  width: 1px;
  height: 55px;
  margin: 0 auto 35px;
}