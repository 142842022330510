/*-----------------------------------------------------------------------------------*/
/*  Our Team Section
/*-----------------------------------------------------------------------------------*/
@section_background_color : #e4eaef;
.our-team {
  background-color: @section_background_color;

  .container {
    padding-top: 130px;
    padding-bottom: 20px;
  }

}

.img-team-variant-one {
  width: 100%;
}

.team-member {
  margin-bottom: 60px;
  figure {

    > a {
      position: relative;
      display: block;
      overflow: hidden;
      img {
        transition: .4s all ease-in-out;

      }

      &:hover {
        .duration-box {
          right: 0;

        }
        img {
          transform: scale3d(1.025, 1.025, 1);
        }

      }
      &:hover {
        .duration-box {

          .duration {
            -webkit-animation-name: dura;
            -webkit-animation-duration: .6s;
            -webkit-animation-delay: .2s;

            animation-name: dura;
            animation-duration: .6s;
            animation-delay: .2s;

          }
          @-webkit-keyframes dura {
            0% {
              margin-left: 20px;
            }
            30% {
              margin-left: 10px;
            }
            60% {
              margin-left: 30px;
            }
            100% {
              margin-left: 20px;
            }
          }
          @keyframes dura {
            0% {
              margin-left: 20px;
            }
            30% {
              margin-left: 10px;
            }
            60% {
              margin-left: 30px;
            }
            100% {
              margin-left: 20px;
            }

          }

        }
      }

    }
  }

  figcaption {
    display: block;
    padding: 20px 18px 2px;
    background-color: @white;
    border-bottom: 1px solid #e4eaef;
  }
  h4 {
    margin-bottom: 6px;
  }
  .designation {
    color: @anchor_color;
    font-weight: 400;
    font-size: 13px;
    transition: .2s all ease-in-out;
  }
  p {
    padding: 14px 18px 22px 18px;
    background-color: #f3f7fa;
    margin-bottom: 0;
    line-height: 23px;
  }
  .social_networks {
    float: none;
    background-color: #f3f7fa;
    border-top: 1px solid #e4eaef;
    margin: 0;
    li {
      margin: 0;
      float: left;
      a {
        width: 54px;
        height: 48px;
        font-size: 20px;
        line-height: 50px;
        border-color: #e4eaef;
        &:hover {
          color: @white;
          background-color: #a9b5bf;
        }
      }
      &.facebook a:hover {
        background-color: #395b89;
      }

      &.twitter a:hover {
        background-color: #2bbfec;
      }

      &.rss a:hover {
        background-color: #ec894d;
      }

      &.gplus a:hover {
        background-color: #d63b3b;
      }

      &.linkedin a:hover {
        background-color: #1184cd;
      }

      &.youtube a:hover {
        background-color: #d02525;
      }

      &.skype a:hover {
        background-color: #0099e5;
      }

      &.instagram a:hover {
        background-color: #457299;
      }

      &.pinterest a:hover {
        background-color: #be2225;
      }
    }
  }
}

.our-team.variant-three {
  background-color: #486bb1;

  .container {
    padding-top: 14px;
    padding-bottom: 0;
  }

  .section-header {
    h2.title {
      font-size: 36px;
      color: @white;
      text-transform: capitalize;
      line-height: 62px;
    }

    p {
      color: #86a2dc;
    }

  }
  .wrapper-member-contents {
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: 0.3s all ease-in-out;
    transition: .3s all ease-in-out;

  }
  .flipped {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
  .team-member-wrapper {
    position: relative;
  }
  .member-image {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    width: 100%;
    height: 405px;
    z-index: 1;
    transition: .2s all ease-in-out;
    &:hover {
      opacity: .7;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 100%;
    }
  }

  .team-member {
    -webkit-perspective: 800;
    perspective: 800;
    position: relative;
    margin-bottom: 0px;

    .member-info {
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      width: 100%;
      height: 100%;
      padding: 18px 18px 0;
      background-color: #4261a1;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2000;

    }
    h4 a {
      color: #b0c8fa;
      &:hover {
        color: @white;
      }
    }
    h5 {
      color: #b0c8fa;
    }
    p {
      padding: 0;
      background: none;
      color: #7c9bda;
      margin-bottom: 15px;
    }

    .social_networks {
      background: none;
      border-color: #486bb1;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      li {
        float: none;
        a {
          border-color: #486bb1;
          color: #6c8ac8;
          &:hover {
            color: @white;
            background: none;
          }
        }
      }
      li:first-child a {
        border-left: none;
      }
    }
  }
}

.our-team.variant-four {
  background-color: #eee9e7;

  .container {
    padding-top: 100px;
  }

  .section-header {
    h2.title {
      font-size: 48px;
      margin-bottom: 25px;
    }
    p {
      line-height: 28px;
    }
  }

  .inner-contents {
    position: relative;
    height: 389px;
    overflow: hidden;
  }

  .member-info {
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    height: 74px;
    padding: 0 20px;
    background-color: @white;
    h4 {
      margin-top: 16px;
      margin-bottom: 8px;
    }
    p {
      color: @heading_color;
      background-color: @white;
      padding: 15px 20px 0;
      margin: 15px -20px 22px;
    }
    &.hover {
      background-color: #f16543;
      p {
        border-top: 1px solid #da502f;
        background-color: #f16543;
        color: @white;
      }
      h4 a,
      h5 {
        color: @white;
      }
      h4 {
        margin-top: 20px;
        margin-bottom: 6px;
        a:hover {
          color: @heading_color !important;
        }
      }
      .social_networks {
        background-color: #f16543;
        border-color: #da502f;
        a {
          color: @white;

        }
      }
    }
  }

  .social_networks {
    border-bottom: 1px solid;
    border-color: #f16543;
    background-color: #ffffff;
    margin: 15px -20px 60px;
    a {
      color: #f16543;
      border-left: 1px solid #f16543;
      i {
        transition: .2s all ease-in-out;
      }
    }
    li:first-child a {
      border-left: none;
    }
  }
}



