/*-----------------------------------------------------------------------------------*/
/*  FAQ
/*-----------------------------------------------------------------------------------*/
.faq-section {
  .title-heading {
    text-align: left;
    font-weight: normal;
    text-transform: none;
    margin-bottom: 10px;
    + p {
      font-size: 15px;
    }
  }
}

.faq-nav {
  float: left;
  width: 23.3%;
  li {
    border-bottom: 1px solid #e4e7e8;
    font-size: 16px;
    line-height: 30px;
    color: #84898b;
    div {
      padding: 14px 40px 14px 0;
      position: relative;
      cursor: pointer;
      &.active,
      &:hover {
        color: #f16543;
        span {
          background-position: center top;
        }
      }
    }
    span {
      display: inline-block;
      position: absolute;
      top: 22px;
      right: 0;
      width: 40px;
      height: 14px;
      background: url("../images/faq-nav-bg.png") no-repeat center bottom;
    }
  }
}

.faq-content {
  float: right;
  width: 65.7%;
  .toggle {
    padding-top: 13px;
  }
  .group {
    width: 100%;
  }
  dt {
    font-weight: normal;
    color: #84898B;
    background: none;
    padding: 9px 10px 9px 37px;
    border-bottom: 1px solid #e2e9eb;
    margin: 0;
    span {
      height: 13px;
      width: 13px;
      top: 11px;
      left: 9px;
      background: url("../images/faq-toggle-bg.png") no-repeat 0 2px;
    }
    &:hover {
      color: #f16543;
      span {
        background-position: 0 -53px;
      }
    }
    &.current {
      font-size: 16px;
      color: #f16543;
      background: none;
      padding: 9px 10px 9px 37px;
      span {
        top: 9px;
        background-position: 0 bottom;
      }
    }
  }
  dd {
    background: none;
    padding: 20px 10px 20px 37px;
  }
}

.feedbackPanel {
  text-align: right;
  margin: 0 -10px 30px -30px;

  .scroll-top {
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 6px;
    i {
      font-size: 10px;
      vertical-align: 2.5px;
      margin-left: 3px;
    }
  }

  form {
    text-align: left;
    padding: 8px 30px;
    background-color: #f5f8f9;
    border: 1px solid #e0e7ea;
    label {
      color: #515557;
      font-size: 15px;
      line-height: 24px;
      font-weight: normal;
      margin: 0;
    }
  }

  .btn {
    border: none;
    color: @white;
    border-radius: 0;
    font-size: 15px;
    padding: 0px 5px;
    margin-left: 8px;
    &:focus {
      outline: none;
    }
    &.yes {
      background-color: #88b84f;
      &:hover {
        background-color: darken(#88b84f, 15%);
      }
    }
    &.no {
      background-color: #e55858;
      &:hover {
        background-color: darken(#e55858, 15%);
      }
    }
  }

}
.faq-title{
  font-weight: normal;
  color: #84898B;
  padding: 9px 10px 9px 37px;
  border-bottom: 1px solid #e2e9eb;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  transition: .2s all ease-in-out;

  &:before{
    font-family: 'fontAwesome';
    content: "\f105";
    position: absolute;
    left: 5px;
    top: 9px;
    font-size: 18px;
    transition: .2s all ease-in-out;

  }
  &:hover{
    color: @anchor_color;
    &:before{
      color: @anchor_color;
    }
  }

}

.faq-body{
  display: none;
  padding: 20px 10px 20px 37px;
}

.faq-title.current{
  color: @anchor_color;
  &:before{
    transform: rotate(90deg);
  }
}

