/*-----------------------------------------------------------------------------------*/
/*  Services
/*-----------------------------------------------------------------------------------*/
.services-wrapper {
  margin-bottom: 50px;
}

.services-items-wrapper {

  .icon-container {
    float: left;
    width: 170px;
    width: 30%;
    height: 170px;
    background-color: #f4f7f8;
    text-align: center;
    i {
      font-size: 60px;
      line-height: 170px;
    }
  }

  article {
    margin-bottom: 30px;
    .title {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 700;
    }
    h2.title {
      font-size: 30px;
      font-weight: 600;
    }
    .content-wrapper {
      float: right;
      padding: 30px 15px 0 30px;
      min-height: 170px;
      height: auto !important;
      height: 170px;
      width: 70%;
    }
    &:hover {
      .content-wrapper {
        background-color: #f4f7f8;
      }
      .icon-container {
        color: @white;
        background-color: #f16543;
      }
    }
  }

}

.call-to-action {
  text-align: center;
  font-size: 36px;
  padding: 40px 0 55px 0;
  border-top: 1px solid #eaeef0;
  border-bottom: 1px solid #eaeef0;
  margin: 100px 0 60px 0;
  i {
    color: #f16543;
    margin-right: 40px;
  }
}

.service-icon {
  float: left;
  text-align: center;
  width: 170px;
  width: 170px;
  height: 170px;
  background-color: #f16543;
  margin-right: 30px;

  i {
    color: @white;
    font-size: 60px;
    line-height: 170px;
  }

}

.services-sidebar {

  .services-item {
    padding: 38px 20px 15px;
    margin-bottom: 10px;
    background: #f9f9f9;

    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 17px;
    }

    &:hover {
      i {
        color: #f16543;
      }
    }

  }

  i {
    transition: .2s all ease-in-out;
    float: left;
    text-align: center;
    width: 80px;
    color: #a0a9ac;
    font-size: 3.5em; //58px;
    padding: 20px 20px 20px 0;
  }

}

