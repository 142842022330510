/*-----------------------------------------------------------------------------------*/
/*  404 Page
/*-----------------------------------------------------------------------------------*/
.error404-body {
  padding-top: 25px;
  margin-bottom: 30px;
  text-align: center;
  color: #777777;
  img {
    margin: 0 auto 75px;
  }
  h2 {
    font-size: 36px;
    line-height: 52px;
    font-weight: normal;
    color: #777777;
    margin-bottom: 15px;
  }
  p {
    color: #777777;
    font-size: 18px;
    line-height: 24px;
  }
}
