//
// Thumbnails
// --------------------------------------------------

// Mixin and adjust the regular image class
.thumbnail {
  .img-thumbnail();
  display: block; // Override the inline-block from `.img-thumbnail`

  > img {
    .img-responsive();
  }
}

// Add a hover state for linked versions only
a.thumbnail:hover,
a.thumbnail:focus {
  border-color: @link-color;
}

// Images and captions
.thumbnail > img {
  margin-left: auto;
  margin-right: auto;
}

.thumbnail .caption {
  padding: @thumbnail-caption-padding;
  color: @thumbnail-caption-color;
}
