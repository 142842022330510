/*-----------------------------------------------------------------------------------*/
/* Lists
/*-----------------------------------------------------------------------------------*/
article {
  ul, ol {
    margin: 25px 0 25px 5px;
    padding-left: 17px;
    li {
      padding: 1px 0 1px 6px;
    }
  }
  ol {
    list-style: decimal;
    li ol,
    li ul, {
      margin: 0;
    }
  }
  ul {
    list-style: disc;
    li ul,
    li ol {
      margin: 0;
    }
  }
  dl {
    dt {
      padding: 6px;
    }
    dd {
      padding: 3px 3px 3px 20px;
    }
  }
}

.list-arrow-bullet,
.list-large-arrow-bullet,
.list-circle-bullet,
.list-empty-circle-bullet {
  ul {
    list-style: none !important;
    padding: 0;
    margin: 25px 0 25px 2px;
    li {
      position: relative;
      padding: 2px 0 2px 24px;
      font-size: 14px;
      line-height: 24px;
      padding-left: 25px;
      &:before {
        position: absolute;
        left: 0;
        color: @anchor_color;
      }
    }
  }
}

.list-arrow-bullet {
  ul li {
    &:before {
      font-family: 'FontAwesome';
      content: "\f101";
    }
  }
}

.list-large-arrow-bullet {
  ul li {
  }
}

.list-empty-circle-bullet {
  ul li {
    &:before {
      font-family: 'FontAwesome';
      content: "\f10c";
      font-size: 10px;
    }
  }
}

.list-circle-bullet {
  ul li {
    &:before {
      font-family: 'FontAwesome';
      content: "\f111";
      font-size: 10px;

    }
  }
}

.theme-ordered-list {
  ol {
    list-style: none;
    *list-style: decimal;
    counter-reset: li;
    margin-left: 0;
    padding-left: 0;
    > li {
      position: relative;
      padding: 2px 0 2px;
      font-size: 14px;
      padding-left: 25px;
      line-height: 24px;
      &:before {
        content: counter(li);
        counter-increment: li;
        display: inline-block;
        color: #f16543;
        font-size: 16px;
        font-weight: 700;
        width: 28px;
        position: absolute;
        left: 0;
      }
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/* font awesome list class
/*-----------------------------------------------------------------------------------*/
.font-list {
  padding-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  li {
    position: relative;
    padding: 9px 0 9px 20px;
    list-style: none;
    border-bottom: 1px solid #DCE3E9;
    font-size: 14px;
    line-height: 24px;
    &:before {
      font-family: 'FontAwesome';
      content: "\f061";
      padding-right: 10px;
      position: absolute;
      left: 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

